export enum Colors {
  White = "#FFFFFF",
  Warning = "#C9B458",
  Success = "#6AAA64",
  Fail = "#AA646A",
  DarkTone1 = "#D7DADC",
  DarkTone2 = "#818384",
  DarkTone3 = "#565758",
  DarkTone4 = "#3A3A3C",
  DarkTone5 = "#272729",
  DarkTone6 = "#1A1A1B",
  DarkTone7 = "#121213",
  LightTone1 = "#1A1A1B",
  LightTone2 = "#787C7E",
  LightTone3 = "#878A8C",
  LightTone4 = "#D3D6DA",
  LightTone5 = "#EDEFF1",
  LightTone6 = "#F6F7F8",
  LightTone7 = "#FFFFFF",
}
